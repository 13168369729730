<template>
    <div class="bottom-container">
        <div class="copyright-1"><el-icon style="width: 2vh; height: 2vh; float: left"><OfficeBuilding /></el-icon><p style="float: left; margin-left: 1vh">运营单位名称：上海叁仟界数字科技有限公司</p></div>
        <div class="copyright-3"><el-icon style="width: 2vh; height: 2vh; float: left"><Iphone /></el-icon><p style="float: left; margin-left: 1vh">联系人电话：19946037026</p></div>
        <div class="copyright-4" @click="link"><el-icon style="width: 2vh; height: 2vh; float: left"><School /></el-icon><p style="float: left; margin-left: 1vh">沪公网安备 31011302007798号</p></div>
        <div class="copyright-5" @click="link2"><el-icon style="width: 2vh; height: 2vh; float: left"><School /></el-icon><p style="float: left; margin-left: 1vh">沪ICP备 2024079066号</p></div>
        <div class="copyright-6" @click="link2"><el-icon style="width: 2vh; height: 2vh; float: left"><ChatLineRound /></el-icon><p style="float: left; margin-left: 1vh">营业证照编号: 27000000202307240500</p></div>
    </div>
</template>
<script>
import { OfficeBuilding, Iphone, School, ChatLineRound} from '@element-plus/icons-vue'
export default {
    name: "BottomComponent",
    components: {  OfficeBuilding, Iphone, School, ChatLineRound },
    setup(){
        const link =() => {
            //链接到备案
            window.open("https://cloud.tencent.com/document/product/243/61412");
        }
        const link2 =() => {
            console.log("https://beian.miit.gov.cn/");
            //链接到备案
            window.open("https://beian.miit.gov.cn/");
        }
        return {
            link,
            link2
        }
    }
}
</script>
<style lang="scss" scoped>
    .bottom-container {
        background-color: #041E38;
        padding-left: 6vh;
        text-align: center;
        font-size: 1.2vh;
        color: #fff;
        letter-spacing: 0.1vh;
        display: flex;
        justify-content: space-around;
        .copyright-1 {
            width: 30vh;
            height: 3vh;
            margin-top: 3vh;
            .el-icon svg {
                width: 1.5vh;
                height: 1.5vh;
                margin-top: -0.5vh;
            }
        }
        .copyright-2 {
            width: 28vh;
            height: 3vh;
            margin-top: 3vh;
            .el-icon svg {
                width: 1.5vh;
                height: 1.5vh;
                margin-top: -0.5vh;
            }
        }
        .copyright-3 {
            width: 28vh;
            height: 3vh;
            margin-top: 3vh;
            .el-icon svg {
                width: 1.5vh;
                height: 1.5vh;
                margin-top: -0.5vh;
            }
        }
        .copyright-4 {
            cursor: pointer;
            width: 28vh;
            height: 3vh;
            margin-top: 3vh;
            .el-icon svg {
                width: 1.5vh;
                height: 1.5vh;
                margin-top: -0.5vh;
            }
        }
        .copyright-5 {
            cursor: pointer;
            width: 28vh;
            text-align: right;
            height: 3vh;
            margin-top: 3vh;
            .el-icon svg {
                width: 1.5vh;
                height: 1.5vh;
                margin-top: -0.5vh;
            }
        }
        .copyright-6 {
            cursor: pointer;
            width: 28vh;
            text-align: right;
            height: 3vh;
            margin-top: 3vh;
            .el-icon svg {
                width: 1.5vh;
                height: 1.5vh;
                margin-top: -0.5vh;
            }
        }
    }
</style>